import React from "react";
import Head from "../../components/head.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Layout from "../../components/layout";

const Peter = () => {
  return (
    <Layout>
      <Head title="Peter Marek" />
      <h3>Peter Marek</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Trenčín, Slovakia
      </h4>
      <p>
        <OutboundLink href="https://ello.co/petermarek">Ello</OutboundLink>
      </p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15424390/ello-optimized-f78fb187.jpg"
        alt="Peter Marek Photography"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/10270976/ello-optimized-6ab43dac.jpg"
        alt="Peter Marek Photography"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/5362727/ello-optimized-4064da47.jpg"
        alt="Peter Marek Photography"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/11470114/ello-optimized-f787717e.jpg"
        alt="Peter Marek Photography"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/14327072/ello-optimized-1aadb5f4.jpg"
        alt="Peter Marek Photography"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/12796200/ello-optimized-5a63a400.jpg"
        alt="Peter Marek Photography"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/12242438/ello-optimized-9a86e773.jpg"
        alt="Peter Marek Photography"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/5679636/ello-optimized-0c78fd80.jpg"
        alt="Peter Marek Photography"
      />
    </Layout>
  );
};

export default Peter;
